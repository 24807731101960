import { render, staticRenderFns } from "./RuxIcon.vue?vue&type=template&id=c3c6c00c&"
import script from "./RuxIcon.vue?vue&type=script&lang=js&"
export * from "./RuxIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../cosmosc2-tool-base/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/cosmos/plugins/cosmosc2-tool-base/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c3c6c00c')) {
      api.createRecord('c3c6c00c', component.options)
    } else {
      api.reload('c3c6c00c', component.options)
    }
    module.hot.accept("./RuxIcon.vue?vue&type=template&id=c3c6c00c&", function () {
      api.rerender('c3c6c00c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "packages/cosmosc2-tool-common/src/components/icons/RuxIcon.vue"
export default component.exports