var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-overlay", {
        staticClass: "overlay",
        attrs: { value: _vm.showUserMenu },
      }),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            transition: "slide-y-transition",
            "offset-y": "",
            "close-on-content-click": false,
            "nudge-width": 120,
            "nudge-bottom": 20,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { size: _vm.size } }, [
                        _vm._v(" mdi-account "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showUserMenu,
            callback: function ($$v) {
              _vm.showUserMenu = $$v
            },
            expression: "showUserMenu",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "text-center" }, [
                _vm.authenticated
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { block: "", color: "primary" },
                            on: { click: _vm.logout },
                          },
                          [_vm._v(" Logout ")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { block: "", color: "primary" },
                            on: { click: _vm.login },
                          },
                          [_vm._v(" Login ")]
                        ),
                      ],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("v-switch", {
                      attrs: { label: "Colorblind mode" },
                      model: {
                        value: _vm.colorblindMode,
                        callback: function ($$v) {
                          _vm.colorblindMode = $$v
                        },
                        expression: "colorblindMode",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }