var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", id: "cosmos-nav-drawer" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { attrs: { "two-line": "" } },
                [
                  _c("v-list-item-icon", [
                    _c("img", { attrs: { src: _vm.logo, alt: "COSMOS" } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "font-weight-bold" },
                        [_vm._v("\n            COSMOS\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-list-item-content",
                    _vm._l(_vm.adminTools, function (tool, name) {
                      return _c(
                        "div",
                        { key: name },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                small: "",
                                href: tool.url,
                                onclick: "singleSpaNavigate(event)",
                              },
                            },
                            [_vm._v("\n              Admin\n            ")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm._l(_vm.shownTools, function (tool, name) {
                return _c(
                  "div",
                  { key: name },
                  [
                    _c(
                      "v-list-item",
                      { staticClass: "pr-0" },
                      [
                        _c("v-list-item-icon", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: tool.url,
                                onclick: "singleSpaNavigate(event)",
                              },
                            },
                            [_c("v-icon", [_vm._v(_vm._s(tool.icon))])],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("v-list-item-content", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: tool.url,
                                onclick: "singleSpaNavigate(event)",
                              },
                            },
                            [_c("v-list-item-title", [_vm._v(_vm._s(name))])],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("v-divider", { attrs: { vertical: "" } }),
                        _vm._v(" "),
                        _c("v-list-item-icon", [
                          _c(
                            "a",
                            { attrs: { href: tool.url, target: "_blank" } },
                            [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            color: "tertiary darken-3",
            id: "cosmos-app-toolbar",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _vm._v(" "),
          _c("v-divider", {
            staticClass: "top-bar-divider-full-height",
            attrs: { vertical: "" },
          }),
          _vm._v(" "),
          _c("span", { staticStyle: { width: "100%" } }, [
            _c("span", { attrs: { id: "cosmos-menu" } }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "justify-right mr-2 pt-2" },
            [_c("scope-selector")],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "justify-right" }, [_c("alert-history")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "justify-right" }, [_c("notifications")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "justify-right" }, [_c("user-menu")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }