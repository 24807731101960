var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "astro-badge",
    { attrs: { status: _vm.status } },
    [
      _c(
        "v-icon",
        { attrs: { color: _vm.color || _vm.statusColor } },
        [
          _vm.$slots.default
            ? _vm._t("default")
            : [_vm._v(" " + _vm._s(_vm.icon) + " ")],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }