var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-badge",
    {
      attrs: {
        color: "transparent",
        left: "",
        inline: _vm.inline,
        "offset-x": _vm.offsetX,
        "offset-y": _vm.offsetY + 12,
      },
      scopedSlots: _vm._u([
        {
          key: "badge",
          fn: function () {
            return [
              _c("astro-status-indicator", {
                attrs: { status: _vm.status, small: "" },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }