var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      attrs: {
        id: "footer",
        app: "",
        color: "tertiary darken-3",
        height: "33",
      },
    },
    [
      _c("img", {
        attrs: { src: _vm.logo, alt: "COSMOS", width: "20", height: "20" },
      }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "footer-text", staticStyle: { "margin-left": "5px" } },
        [_vm._v("\n    COSMOS " + _vm._s(_vm.cosmosVersion) + " © 2022\n  ")]
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "white--text text-decoration-underline",
          attrs: { href: _vm.sourceUrl },
        },
        [_vm._v("\n    Source\n  ")]
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c("div", { staticClass: "justify-right" }, [_c("clock-footer")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }